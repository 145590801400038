<template>
    <b-card>
        <b-row>
            <b-col cols="9">
                <b-button class="m-1 " v-ripple.400="'#1396EB'" variant="primary" v-b-modal.modal-primaryadd>
                    Добавление
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-form-select class="m-1" v-model="defaultLang" :options="langs" text-field="name" value-field="code"
                    @change="getItems" />
            </b-col>
        </b-row>
        <b-modal @ok="add" @hidden="resetModal" id="modal-primaryadd" ok-only ok-title="Добавить"
            modal-class="modal-primary" centered title="Добавление">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="faq.title" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Язык" label-for="langs">
                        <b-form-select v-model="faq.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
        <b-table :fields="tableColumns" responsive="sm" :items="faqs">
            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-button class="btn-icon mr-1" variant="gradient-primary" id="gradient-primaryy"
                        @click="edit(data.item)" v-b-modal.modal-primaryedit>
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button class="btn-icon" variant="gradient-danger" @click="confirmDelete(data.item)">
                        <feather-icon icon="TrashIcon" />
                    </b-button>
                </div>
            </template>
        </b-table>
        <b-modal @hidden="resetModal" @ok="handleEdit" id="modal-primaryedit" ok-only ok-title="Редактировать"
            modal-class="modal-primary" centered title="Редактирование">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="faq.title" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Язык" label-for="langs">
                        <b-form-select v-model="faq.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </b-card>
</template>
  
<script>
import { BFormSelect, BFormInput, BRow, BCol, BFormGroup, BModal, VBModal, BButton, BCard, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { $themeConfig } from '@themeConfig'

export default {
    components: {
        BFormSelect,
        ToastificationContent,
        BFormInput,
        BRow,
        BCol,
        BFormGroup,
        BModal,
        VBModal,
        BCard,
        BButton,
        BTable,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {

        return {
            defaultLang: 'ru',
            langs: [],
            items: [],
            faq: {
                title: '',
                lang: ""
            },
            faqs: [],
            tableColumns: [
                {
                    key: 'id',
                    label: '#',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Название',
                    sortable: true,
                },

                {
                    key: 'actions',
                    label: 'Действия',
                }]
        }
    },
    mounted() {
        this.getLangs()
        this.getFAQ()
    },
    methods: {
        getItems() {
            this.getFAQ()
        },
        getLangs() {
            axios.get(`${$themeConfig.app.API}langs`)
                .then(res => {
                    this.langs = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },
        getFAQ() {
            const getfaq = {
                method: 'get',
                url: `${$themeConfig.app.API}faq-category`,
                headers: {
                    'Accept-Language': this.defaultLang
                }
            };
            axios(getfaq)
                .then(res => {
                    this.faqs = res.data.data

                })
                .catch(er => {
                    console.log(er)
                })
        },
        async add() {
            const myFormData = new FormData()
            myFormData.append('name', this.faq.title)
            myFormData.append('lang_id', this.faq.lang)
            await axios({
                method: 'post',
                url: `${$themeConfig.app.API}faq-category`,
                data: myFormData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(() => {
                    this.addStatus = true
                    this.getFAQ()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Успешно',
                            text: 'Запись успешно добавлен',
                            icon: 'CheckSquareIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(e => {
                    this.addStatus = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Ошибка',
                            text: e.message,
                            icon: 'CheckSquareIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
        resetModal() {
            this.faq.title = ''
            this.faq.lang = ''
            this.faq.id = ''
        },
        async handleEdit() {
            const myFormData = new FormData()
            myFormData.append('name', this.faq.title)
            myFormData.append('lang_id', this.faq.lang)
            myFormData.append('_method', 'PUT')
            if (this.faq.id !== '') {
                await axios({
                    method: 'post',
                    url: `${$themeConfig.app.API}faq-category/${this.faq.id}`,
                    data: myFormData,
                    config: {},
                })
                    .then(() => {
                        this.getFAQ()
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Успешно',
                                text: 'Данные были изменены',
                                icon: 'CheckSquareIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ошибка',
                                text: e.message,
                                icon: 'CheckSquareIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        },
        edit(data) {
            this.faq.title = data.name
            this.faq.lang = data.lang.id
            this.faq.id = data.id
        },
        confirmDelete(data) {
            this.$bvModal
                .msgBoxConfirm('После удаления данные восстановить нельзя будет!', {
                    title: 'Вы уверены что хотите удалить?',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отменить',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        axios.delete(`${$themeConfig.app.API}faq-category/${data.id}`)
                            .then(() => {
                                this.getFAQ()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Успешно',
                                        text: 'Данные были удалены',
                                        icon: 'CheckSquareIcon',
                                        variant: 'success',
                                    },
                                })
                            })
                            .catch(e => {
                                console.log(e)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Ошибка',
                                        text: e.message,
                                        icon: 'CheckSquareIcon',
                                        variant: 'dander',
                                    },
                                })
                            })
                    }
                })
            },
    }
}
</script>